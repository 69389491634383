// Importing type
import * as apiTypes from './types';
import { Account, AccountDetails } from '@features/account/types';

import { GET_ACCOUNTS } from './graphql/queries';
import apolloClientInstance from '@apolloClientInstance';
import { accountDefaultConfiguration } from '@data/account';

const getAccount: apiTypes.APIGetAccount = async (name: string) => {
  let account: Account;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  account = accountDefaultConfiguration;
  if (!account) {
    error = true;
    errorDetails = 'No account found.';
  }

  return {
    error,
    account,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAccount: apiTypes.APISaveAccount = async (account: Account) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!account) {
    error = true;
    errorDetails = 'No account found.';
  }
  return {
    account,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getAccounts: apiTypes.APIGetAccountsDetails = async () => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();

  const result = await apolloClient.query({
    query: GET_ACCOUNTS,
  });

  const accountsDetails: AccountDetails[] = result.data.accounts;
  const activeAccountDetails = accountsDetails.filter(({ isActive }) => isActive);

  if (!activeAccountDetails) {
    error = true;
    errorDetails = result.data.message ? result.data.message : 'No account found.';
  }

  return {
    error,
    accountsDetails: activeAccountDetails,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { saveAccount, getAccount, getAccounts };
