import { takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import * as types from './types';
import redirectionsSlice from '@features/redirections/ducks/redirectionsSlice';
import {
  queryParamKeysEnum,
  appRoutesEnum,
  layoutRoutesEnum,
  authRoutesEnum,
  hashParamKeysEnum,
} from '@routes/types';
import { activeAccountIdStateSelector } from '@redux/selectors';

// Source catalog redirections
export function* redirectToSourceCatalogSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SOURCE_CATALOG}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSourceCatalogSuccess.type });
}

// Monitoring redirections
export function* redirectToMonitoringSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.MONITORING}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToMonitoringSuccess.type });
}

// Account resources executions monitoring redirections
export function* redirectToAccountResourcesExecutionsMonitoringSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.ACCOUNT_RESOURCES_EXECUTIONS_MONITORING}`;
  yield put(push(toRoute));
  yield put({
    type: redirectionsSlice.actions.redirectToAccountResourcesExecutionsMonitoringSuccess.type,
  });
}

// Connection redirections
export function* redirectToConnectionSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CONNECTIONS}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToConnectionSuccess.type });
}

export function* redirectToConnectionBuilderSaga({
  payload,
}: types.RedirectToConnectionBuilderSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const { connectionId } = payload;
  const toRoute: string = connectionId
    ? `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CONNECTIONS_EDIT}?${queryParamKeysEnum.ELEMENT}=${connectionId}`
    : `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CONNECTIONS_CREATE}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToConnectionBuilderSuccess.type });
}
// Dataset redirections
export function* redirectToDatasetsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.DATASETS}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToDatasetsSuccess.type });
}
export function* redirectToDatasetBuilderSaga({ payload }: types.RedirectToDatasetBuilderSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const { datasetId } = payload;
  const toRoute: string = datasetId
    ? `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.DATASETS_PREPARE_DATASET}?${queryParamKeysEnum.ELEMENT}=${datasetId}`
    : `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.DATASETS_PREPARE_DATASET}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToDatasetBuilderSuccess.type });
}

// New Syncs redirections
export function* redirectToNewSyncsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.NEW_SYNCS}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToNewSyncsSuccess.type });
}
export function* redirectToNewSyncBuilderSaga({ payload }: types.RedirectToNewSyncBuilderSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const { syncId } = payload;
  const toRoute: string = syncId
    ? `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.NEW_SYNCS_EDIT}?${queryParamKeysEnum.ELEMENT}=${syncId}`
    : `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.NEW_SYNCS_CREATE}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToNewSyncBuilderSuccess.type });
}

// Audience redirections
export function* redirectToAudiencesSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.AUDIENCES}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToAudiencesSuccess.type });
}

export function* redirectToAudiencesCreatorSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.AUDIENCES_CREATE}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToAudiencesCreatorSuccess.type });
}

export function* redirectToPrepareAudienceSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.AUDIENCES_PREPARE_DATA}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToPrepareAudienceSuccess.type });
}

export function* redirectToAudiencesEditorSaga({ payload }: types.RedirectToAudiencesEditorSaga) {
  const { audienceKey } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.AUDIENCES_EDIT_DATA}?${queryParamKeysEnum.ELEMENT}=${audienceKey}${hashParamKeysEnum.VIEW}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToAudiencesEditorSuccess.type });
}

export function* redirectToSqlAudienceEditorSaga({
  payload,
}: types.RedirectToSqlAudiencesEditorSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);

  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SQL_AUDIENCES}${
    payload.audienceKey ? `?${queryParamKeysEnum.ELEMENT}=${payload.audienceKey}` : ''
  }`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSqlAudienceEditorSuccess.type });
}

export function* redirectToSqlAudienceViewSaga({ payload }: types.RedirectToSqlAudiencesViewSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);

  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.AUDIENCE_SQL_VIEW}${
    payload.audienceKey ? `?${queryParamKeysEnum.ELEMENT}=${payload.audienceKey}` : ''
  }`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSqlAudienceViewSuccess.type });
}

// Syncs redirections
export function* redirectToSyncsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SYNCS}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSyncsSuccess.type });
}
export function* redirectToSyncsCreatorSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SYNCS_CREATE}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSyncsCreatorSuccess.type });
}
export function* redirectToSyncsEditorSaga({ payload }: types.RedirectToSyncsEditorSaga) {
  const { syncKey } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SYNCS_EDIT}?${queryParamKeysEnum.ELEMENT}=${syncKey}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToSyncsEditorSuccess.type });
}

// Account management
export function* redirectToAccountManagementSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.ACCOUNT_MANAGEMENT}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToAccountManagementSuccess.type });
}
// Destination
export function* redirectToDestinationSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.DESTINATION}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToDestinationSuccess.type });
}

export function* redirectToLogoutSaga() {
  const toRoute: string = `${authRoutesEnum.LOGOUT}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToLogoutSuccess.type });
}
export function* redirectToAccountSelectorSaga() {
  const toRoute: string = `${authRoutesEnum.ACCOUNT_SELECTOR}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToAccountSelectorSuccess.type });
}

export function* redirectToAccountSettingsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  yield put(push(`${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.SETTINGS_ACCOUNT}`));
  yield put({ type: redirectionsSlice.actions.redirectToAccountSettingsSuccess.type });
}
export function* redirectToProfileSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  yield put(push(`${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.PROFILE}`));
  yield put({ type: redirectionsSlice.actions.redirectToProfileSuccess.type });
}
export function* redirectToPlanSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  yield put(push(`${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.PLAN}`));
  yield put({ type: redirectionsSlice.actions.redirectToPlanSuccess.type });
}

export function* redirectToDataWarehouseSettingsSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.CREATE_DATAWAREHOUSE}`;

  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToDataWarehouseSettingsSuccess.type });
}

export function* redirectToBillingSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.BILLING}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToBillingSuccess.type });
}

export function* redirectToDashboardSaga() {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const toRoute: string = `${layoutRoutesEnum.APP}/${accountId}${appRoutesEnum.DASHBOARD}`;
  yield put(push(toRoute));
  yield put({ type: redirectionsSlice.actions.redirectToDashboardSuccess.type });
}

// other links
export function* redirectToLinkSaga({ payload }: types.RedirectToLinkSaga) {
  const { link } = payload;
  yield put(push(link));
  yield put({ type: redirectionsSlice.actions.redirectToLinkSuccess.type });
}
export const redirectionsSagas = [
  takeLatest(redirectionsSlice.actions.redirectToBilling.type, redirectToBillingSaga),
  takeLatest(redirectionsSlice.actions.redirectToDashboard.type, redirectToDashboardSaga),

  // SOURCE CATALOG
  takeLatest(redirectionsSlice.actions.redirectToSourceCatalog.type, redirectToSourceCatalogSaga),

  // MONITORING
  takeLatest(redirectionsSlice.actions.redirectToMonitoring.type, redirectToMonitoringSaga),

  // ACCOUNT RESOURCES EXECUTIONS MONITORING
  takeLatest(
    redirectionsSlice.actions.redirectToAccountResourcesExecutionsMonitoring.type,
    redirectToAccountResourcesExecutionsMonitoringSaga
  ),

  // CONNECTION
  takeLatest(redirectionsSlice.actions.redirectToConnection.type, redirectToConnectionSaga),
  takeLatest(
    redirectionsSlice.actions.redirectToConnectionBuilder.type,
    redirectToConnectionBuilderSaga
  ),
  // DATASET
  takeLatest(redirectionsSlice.actions.redirectToDatasets.type, redirectToDatasetsSaga),
  takeLatest(redirectionsSlice.actions.redirectToDatasetBuilder.type, redirectToDatasetBuilderSaga),

  //NEW SYNCS
  takeLatest(redirectionsSlice.actions.redirectToNewSyncs.type, redirectToNewSyncsSaga),
  takeLatest(redirectionsSlice.actions.redirectToNewSyncBuilder.type, redirectToNewSyncBuilderSaga),

  // AUDIENCE
  takeLatest(redirectionsSlice.actions.redirectToAudiences.type, redirectToAudiencesSaga),
  takeLatest(
    redirectionsSlice.actions.redirectToAudiencesCreator.type,
    redirectToAudiencesCreatorSaga
  ),
  takeLatest(
    redirectionsSlice.actions.redirectToPrepareAudience.type,
    redirectToPrepareAudienceSaga
  ),
  takeLatest(
    redirectionsSlice.actions.redirectToAudiencesEditor.type,
    redirectToAudiencesEditorSaga
  ),
  takeLatest(
    redirectionsSlice.actions.redirectToSqlAudienceView.type,
    redirectToSqlAudienceViewSaga
  ),
  takeLatest(
    redirectionsSlice.actions.redirectToSqlAudienceEditor.type,
    redirectToSqlAudienceEditorSaga
  ),
  // SYNCS
  takeLatest(redirectionsSlice.actions.redirectToSyncs.type, redirectToSyncsSaga),
  takeLatest(redirectionsSlice.actions.redirectToSyncsCreator.type, redirectToSyncsCreatorSaga),
  takeLatest(redirectionsSlice.actions.redirectToSyncsEditor.type, redirectToSyncsEditorSaga),
  // Account management
  takeLatest(
    redirectionsSlice.actions.redirectToAccountManagement.type,
    redirectToAccountManagementSaga
  ),
  // Destination
  takeLatest(redirectionsSlice.actions.redirectToDestination.type, redirectToDestinationSaga),

  // Logout
  takeLatest(redirectionsSlice.actions.redirectToLogout.type, redirectToLogoutSaga),
  // AccountSelector
  takeLatest(
    redirectionsSlice.actions.redirectToAccountSelector.type,
    redirectToAccountSelectorSaga
  ),
  // datawarehouse
  takeLatest(
    redirectionsSlice.actions.redirectToDataWarehouseSettings.type,
    redirectToDataWarehouseSettingsSaga
  ),

  // Settings account
  takeLatest(
    redirectionsSlice.actions.redirectToAccountSettings.type,
    redirectToAccountSettingsSaga
  ),
  // Settings profile
  takeLatest(redirectionsSlice.actions.redirectToProfile.type, redirectToProfileSaga),
  // Settings plan
  takeLatest(redirectionsSlice.actions.redirectToPlan.type, redirectToPlanSaga),
  // other links
  takeLatest(redirectionsSlice.actions.redirectToLink.type, redirectToLinkSaga),
];
